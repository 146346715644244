:root[data-mantine-color-scheme='dark'] {
	--mantine-color-body: var(--mantine-color-dark-8);
}
@keyframes Bar {
	0% {
		color: rgba(226, 192, 68, 0);
	}
	50% {
		color: #fff;
	}
	100% {
		color: rgba(226, 192, 68, 0);
	}
}
.custom-caret {
	animation: Bar 1s step-end infinite;
	padding-left: 2px;
}

.caret-label .custom-caret {
	display: none;
}
.caret-label:has(input:focus) .custom-caret {
	display: inline;
}
.active_btn {
	background-color: #0c345a;
}
.m-8fdc1311 {
	display: none !important;
}

.recharts-tooltip-wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 200px;
	/* width: auto; */
	border-radius: 8px;
	background-color: #333;
	color: #eee;
	padding: 10px 0;
}
.recharts-tooltip-wrapper-top div {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
