.header {
  height: 56px;
  margin-bottom: 30px;
  background-color: var(--mantine-color-body);
  border-bottom: 1px solid var(--mantine-color-dark-4);
  position: sticky;
  top: 0;
  z-index: 5;
}

.inner {
  height: 56px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.link {
  display: block;
  line-height: 1;
  padding: 8px 12px;
  border-radius: var(--mantine-radius-sm);
  text-decoration: none;
  color: var(--mantine-color-dark-0);
  font-size: var(--mantine-font-size-sm);
  font-weight: 500;
}
@media only screen and (max-width: 768px) {
  .links {
    position: fixed;
    inset: 0;
    background-color: var(--mantine-color-body);
    margin: 0 !important;
    z-index: -1;
    transition: 300ms ease;
  }
  .links[opened='false'] {
    flex-direction: column;
    justify-content: center;
    translate: 0 -100%;
    opacity: 0;
  }
  .links[opened='true'] {
    flex-direction: column;
    justify-content: center;
    opacity: 1;
    translate: 0%;
  }
  .search {
    width: 150px;
  }
}
