.footer {
  margin-top: 120px;
  border-top: 1px solid var(--mantine-color-dark-5);
  width: 100%;
}
.inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: var(--mantine-spacing-xl);
  padding-bottom: var(--mantine-spacing-xl);
}
.links a {
  text-decoration: none;
}

.link {
  display: flex;
  align-items: center;
  gap: 10px;
}

.link > img {
  width: 20px;
  height: 20px;
}

@media (max-width: 768px) {
  .inner {
    flex-direction: column;
    text-align: center;
  }
  .links {
    margin-top: var(--mantine-spacing-md);
  }
}
