.th {
  padding: 0;
}
.control {
  width: 100%;
  padding: var(--mantine-spacing-xs) var(--mantine-spacing-md);
}
.icon {
  width: 21px;
  height: 21px;
  border-radius: 21px;
}
.root {
  background-image: linear-gradient(
    -60deg,
    var(--mantine-color-blue-4) 0%,
    var(--mantine-color-blue-7) 100%
  );
  padding: var(--mantine-spacing-xl);
  border-radius: var(--mantine-radius-md);
  padding-top: 80px;
  width: 100%;
}
@media (max-width: 576px) {
  .root {
    display: flex;
    flex-direction: column;
  }
}

.stat {
  min-width: 98px;
  padding-top: var(--mantine-spacing-xl);
  min-height: 140px;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  span {
    color: white;
  }
}
.hours {
  height: 140px;
  width: 120px;
  overflow: auto;
  padding: 10px;
  border: 2px solid #eee;
  border-radius: 8px;
}
.hours::-webkit-scrollbar {
  width: 1px;
  /* background-color: #333;
	border-radius: 2px; */
}
.date {
  position: absolute;
  top: 5px;
  left: 50%;
  translate: -50% 0;
  font-size: 20px;
  color: #eee;
}
.data_picker {
  position: absolute;
  top: 30px;
  width: 170px;
}
.label {
  text-transform: uppercase;
  font-weight: 700;
  font-size: var(--mantine-font-size-xs);
  font-family: var(--mantine-font-family);
  color: var(--mantine-color-blue-7) !important;
  line-height: 1.2;
}
.value {
  font-family: var(--mantine-font-family);
  font-size: var(--mantine-font-size-sm);
  font-weight: 600;
  color: var(--mantine-color-black);
}
.icon_ {
  color: var(--mantine-color-blue-7);
}
.month_picker {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 0;
}
.iframe {
  width: 100%;
  min-height: 400px;
  border: 0;
  border-radius: var(--mantine-spacing-sm);
  margin-top: var(--mantine-spacing-lg);
}

.diagrams {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 50px 0px;
}

.diagramItem {
  width: 400px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
}

@media (max-width: 550px) {
  .root {
    padding-top: 150px;
  }
  .data_picker {
    left: 50%;
    translate: -50% 0;
  }
  .date {
    top: 60px;
  }
}
