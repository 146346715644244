.root {
  padding-top: 80px;
  padding-bottom: 80px;
  align-items: center;
  justify-content: center;
  display: flex;
  flex: 1;
}
.inner {
  position: relative;
}
.image {
  position: absolute;
  inset: 0;
  opacity: 0.75;
  color: var(--mantine-color-dark-6);
}
.content {
  padding-top: 220px;
  position: relative;
  z-index: 1;
}
@media (max-width: 768px) {
  .content {
    padding-top: 120px;
  }
}
.title {
  font-family: Greycliff CF, var(--mantine-font-family);
  text-align: center;
  font-weight: 900;
  font-size: 38px;
  text-wrap: balance;
}
@media (max-width: 768px) {
  .title {
    font-size: 32px;
  }
}
.description {
  text-wrap: balance;
  margin-top: var(--mantine-spacing-xl);
  margin-bottom: calc(var(--mantine-spacing-xl) * 1.5);
}
